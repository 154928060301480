.app__logout {
  margin-top: 12px;
  color: var(--semUserColorWidgetLinkDefault);
  font: var(--semUserWidgetLinkDefault);
}

.app__content {
  overflow-y: auto;
  max-height: calc(100% - 64px);
  position: fixed;
  top: 64px;
}

.app__skip:focus {
  top: 0;
  z-index: 2000;
  -webkit-transform: translate3d(0px, 0px, 2000px);
  width: 74px;
  padding: 7px;
  background-color: var(--colorPrimary50);
  outline-color: var(--colorPrimary200);
  color: var(--colorPrimary200);
  font: var(--semUserWidgetLinkDefault);
}

@media only screen and (min-width: 1272px) {
  .app__content {
    position: fixed;
    top: 95px;
    right: 0;
    width: 100vw;
    max-height: calc(100% - 95px);
  }
}
