.invitations__title,
.invitations__info,
.invitations__input,
.invitations__list {
  grid-column: 1 / 5;
}

.invitations__title {
  font: var(--semHeadlineDesktopH1);
  font-size: 32px;
  display: flex;
  align-items: center;
}

.invitations__back {
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
}

.invitations__back:hover > svg,
.invitations__back:focus-within > svg {
  stroke: var(--semButtonColorSecondaryLabelHover);
}

.invitations__info {
  margin: 16px 0 48px;
  color: var(--colorTypoHeadingDefault);
  font: var(--semFormTypoInput);
}

.invitations__input {
  margin: 16px 0 48px;
  display: flex;
  align-items: end;
  flex-direction: column;
}

.invitations__list--blank {
  margin-top: 24px;
  font: var(--semTableText);
  color: var(--semTableColorWidgetHeadlines);
  font-style: italic;
}

@media only screen and (min-width: 664px) {
  .invitations__title,
  .invitations__info,
  .invitations__list {
    grid-column: 1 / 9;
  }

  .invitations__input {
    grid-column: 1 / 5;
  }
}

@media only screen and (min-width: 1272px) {
  .invitations__title,
  .invitations__info,
  .invitations__input,
  .invitations__list {
    grid-column: 2 / 12;
  }

  .invitations__input {
    grid-column: 2 / 7;
  }
}
