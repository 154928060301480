.imprint {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.imprint__contents {
  z-index: 20;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  max-width: 1223px;
  margin: 16px;
  overflow: scroll;
}

.imprint__contents > * {
  max-width: 704px;
}

.imprint__title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  letter-spacing: 0.02em;
  margin: 40px 0 16px;
  position: relative;
}

.imprint__title--page-titel {
  font-size: 64px;
  line-height: 64px;
}

.imprint__title--with-divider:after {
  content: "";
  width: 80px;
  height: 4px;
  background: var(--colorPrimary200);
  position: absolute;
  bottom: -4px;
  left: 0;
}

.imprint__paragraph {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  margin: 0;
}

.imprint__link {
  margin: 40px 0;
}
