.account__title,
.account__section {
  grid-column: 1 / 5;
}

.account__title {
  font: var(--semHeadlineDesktopH1);
  font-size: 32px;
  display: flex;
  align-items: center;
}

.account__subtitle {
  padding: 64px 0 24px;
  font-size: 24px;
  font-family: "Ubuntu Condensed";
  margin: 0;
}

.account__section a {
  text-decoration: none;
}

.account__back {
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
}
.account__back:hover > svg,
.account__back:focus-within > svg {
  stroke: var(--semButtonColorSecondaryLabelHover);
}
.account__section {
  margin: 16px 0 48px;
  color: var(--colorTypoHeadingDefault);
  font: var(--semFormTypoInput);
}

@media only screen and (min-width: 664px) {
  .account__title,
  .account__section {
    grid-column: 1 / 9;
  }
}
@media only screen and (min-width: 1272px) {
  .account__title,
  .account__section {
    grid-column: 2 / 12;
  }
}
