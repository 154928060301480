.terms__title,
.terms__info,
.terms__input,
.terms__text {
  grid-column: 1 / -1;
}

.terms__title {
  display: flex;
  gap: 8px;
  font: var(--semHeadlineDesktopH1);
  font-size: 32px;
  align-items: center;
  margin-bottom: 4px;
}

.terms__title--valid-from {
  font: var(--semTableText);
  color: var(--semTableColorWidgetHeadlines);
  margin-bottom: 24px;
}

.terms__title--h2 {
  font-size: 28px;
}

.terms__edit {
  padding-left: 12px;
}

.terms__edit:hover svg,
.terms__edit:focus-within svg {
  stroke: var(--semButtonColorSecondaryLabelHover);
}

.terms__info {
  margin: 16px 0 48px;
  color: var(--colorTypoHeadingDefault);
  font: var(--semFormTypoInput);
}

.terms__input {
  margin: 16px 0 48px;
  display: flex;
  align-items: end;
  flex-direction: column;
}

.terms__input textarea {
  height: 200px;
}

.terms__loading {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
}

.terms__text {
  display: flex;
  flex-direction: column;
  align-items: start;
  font: var(--semTableText);
  padding-bottom: 32px;
}

.terms__text--error {
  margin-top: 24px;
  font: var(--semTableText);
  color: var(--semTableColorWidgetHeadlines);
  font-style: italic;
}
