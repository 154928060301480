@font-face {
  font-family: "Roboto";
  src: url("./resources/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url("./resources/fonts/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Ubuntu Condensed";
  src: url("./resources/fonts/UbuntuCondensed-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./resources/fonts/Ubuntu-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./resources/fonts/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Ubuntu";
  src: url("./resources/fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: 700;
}

.typographyTypoHeadingDesktopH2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-indent: 0px;
}

.typographyTypoParagraphAllDevicesP {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-indent: 0px;
}

body {
  margin: 0;
}

.sr-only {
  position: absolute;
  top: -3000em;
}

.page__grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  column-gap: 20px;
  margin: 0 24px 24px 24px;
}

div.modalContent {
  border-color: var(--colorPrimary200);
}

@media only screen and (min-width: 664px) {
  .page__grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media only screen and (min-width: 1272px) {
  .page__grid {
    grid-template-columns: repeat(12, 78px);
    column-gap: 24px;
    margin: 0px;
  }
}
