.enrichedTable .table {
  overflow-x: clip;
}
.enrichedTable .tableRowElement {
  overflow: unset;
}

.enrichedTable__cell--button {
  border: none;
  background-color: unset;
  cursor: pointer;
  color: black;
  font: var(--semTableText);
  white-space: nowrap;
  padding: 0;
}

.enrichedTable__cell--button:hover {
  color: #ff3d00;
}

.enrichedTable__cell--link {
  cursor: pointer;
  color: black;
  font: var(--semTableText);
  white-space: nowrap;
  display: block;
  padding: 0;
}

.enrichedTable__cell--link:hover {
  color: #ff3d00;
}

.enrichedTable__cell--text {
  font: var(--semTableText);
}

.enrichedTable__cellContainer.withIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  max-width: calc(100% - 48px);
}

.enrichedTable__cellContainer > *:last-child {
  flex-shrink: 0;
}

.enrichedTable__cellContainer .auxiliaryToolTip {
  word-break: break-word;
  white-space: normal;
}

.enrichedTable__cellContainer .auxiliaryToolTip::after {
  transform: translate(-50%);
}

.tableHeaderExpand {
  width: 16px;
}

.tableHeader.tableHeaderSortArrowColumn:first-child {
  padding-left: 10px;
}
