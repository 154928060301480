.authErrorPage {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.authErrorPage__contents {
  z-index: 20;
  display: grid;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  margin: 0 24px;
  height: calc(100% - 48px);
  row-gap: 48px;
  grid-template-rows: 1fr auto 1fr;
  align-items: center;
}

.authErrorPage__title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  letter-spacing: 0.02em;
  width: min(976px, 100vw);
  padding-bottom: 24px;
  margin-top: 0;
  font-size: 64px;
  line-height: 64px;
  align-self: end;
  text-align: start;
}

.authErrorPage__message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  align-items: center;
  width: calc(1024px / 3);
  gap: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: start;
}

.authErrorPage__link {
  color: var(--semButtonColorPrimaryBackgroundDefault);
  padding: var(--semButtonSpacingPrimaryPaddingTopBottomDefault)
    var(--semButtonSpacingPrimaryPaddingLeftRightDefault);
  font: var(--semButtonTypoPrimaryDefault);
}

.authErrorPage__link:hover {
  color: var(--semButtonColorSecondaryLabelHover);
}

.authErrorPage__link:active {
  color: var(--semButtonColorSecondaryLabelPressed);
}

.authErrorPage__background {
  z-index: 10;
  position: absolute;
  overflow-y: hidden;
  width: calc((100vw - 1024px) / 2 + (1024px / 3));
  height: 100%;
  background-size: cover;
  border-radius: 10000px 0 0 10000px;
  right: 0;
}
