.home__title {
  color: var(--colorTypoHeadingDefault);
  margin: 0;
  grid-column: 1 / 5;
  margin: 32px 0px 32px 0px;
  font: var(--semHeadlineDesktopH1);
}

.home__search {
  grid-column: 1 / 5;
  margin-bottom: 24px;
}

.home__applications {
  grid-column: 1 / 5;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: 64px;
  flex-wrap: wrap;
}

.home__invitations {
  grid-column: 1 / 5;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  font: var(--semInputfieldTypoInputDefault);
  color: var(--colorTypoHeadingDefault);
}

.home__activities {
  grid-column: 1 / 5;
}

.home-activities__title {
  margin-bottom: 32px;
  font: var(--semHeadlineDesktopH1);
  font-size: 32px;
}

.home-activities__list {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.home-activities__news {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.home-activities__news:hover img,
.home-activities__news:focus img {
  transform: scale(1.2);
}

.home-activities__news-image {
  overflow: hidden;
  height: 100px;
}

.home-activities__news-image > img {
  height: 100px;
  overflow: hidden;
  width: 100%;
  transform: scale(1);
  transition: 0.2s all ease-in-out;
  object-fit: cover;
}

.home-activities__news-title {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 8px 0 8px 0;
  color: var(--colorTypoHeadingDefault);
  font: var(--semFormTypoInput);
  font-weight: 500;
}

.home-activities__news-caption {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--colorTypoHeadingDefault);
  font: var(--semSnackbarTypoLabel);
}

@media only screen and (min-width: 664px) {
  .home__title,
  .home__search,
  .home__applications,
  .home__invitations,
  .home__activities {
    grid-column: 3 / 7;
  }

  .home__search {
    margin-bottom: 28px;
  }
}

@media only screen and (min-width: 1272px) {
  .home__title,
  .home__search,
  .home__applications,
  .home__invitations,
  .home__activities {
    grid-column: 4 / 10;
  }

  .home__title {
    margin: 46px 0px 40px 0px;
  }

  .home__search,
  .home__applications,
  .home__invitations {
    margin-bottom: 56px;
  }

  .home-activities__list {
    flex-direction: row;
    gap: 24px;
  }

  .home-activities__news-image {
    width: 180px;
    height: 100px;
  }

  .home-activities__news {
    width: 180px;
  }
}
