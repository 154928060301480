.search-bar__row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.search-bar__row > input {
  width: 100%;
  color: var(--colorBase500);
  padding: 0;
  background: none;
  border: none;
  outline: none;
  font: var(--semInputfieldTypoInputDefault);
}

.search-bar__underline {
  height: 1px;
  width: 100%;
  background-color: var(--colorBase900);
}

.search-bar:focus-within .search-bar__underline {
  background-color: #ff3d00;
}

.search-bar__icon {
  fill: none;
  stroke: var(--colorBase500);
}
